import "./styles.scss";
import React, { useEffect, useState } from 'react';
import { Row, Col, Image } from "antd";
import Modal from 'shared/components/modal';
import Paragraph from "shared/components/paragraph";
import Button from "shared/components/bButton";
// import useWindowSize from "shared/hooks/use-window-size";
import { getFirstNameAndLastName } from "shared/utils/helper";
import classNames from "classnames";
import { getPaymentSecret } from "modules/website/redux/operators";
import Spinner from "shared/components/spinner";
import Payment from "modules/website/pages/newPreviewMode/components/payment";
// import Input from "shared/components/binput";
// import { useForm } from "antd/lib/form/Form";
import { useNavigate } from "react-router-dom";
import FollowOurVillieModal from "../followOurVillieModal";
import { Mixpanel } from "shared/services/mixpanel";
import { PostHog } from "shared/services/posthog";

// import Input from "shared/components/binput";

interface props {
    show: boolean
    close: any
    contributionType: any
    website: any
}

const brandsWithOutBtn = ["Believe Diapers", "SquareBaby Baby Food"]

const ContributionModal: React.FC<props> = ({ show, close, contributionType, website }) => {
    const navigate = useNavigate()
    // const [form] = useForm()
    // const { width } = useWindowSize()
    const [donation, setDonation] = useState<any>(35)
    const [preselectedDonation, setPreSelectedDonation] = useState<any>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [step, setStep] = useState(1)
    const [stripeClientSecret, setStripeClientSecret] = useState(null)
    // const [message, setMessgae] = useState(null)
    const [isFollowOurVillieModalVisible, setIsFollowOurVillieModalVisible] = useState(false)

    const clientSecret: any = new URLSearchParams(window.location.search).get(
        'payment_intent_client_secret'
    );
    const user_donation: any = new URLSearchParams(window.location.search).get(
        'donation'
    );
    
    useEffect(() => {
        setDonation(contributionType?.type === 'fund' ? 35 : contributionType?.initialAmount)
    } , [contributionType])
    
    useEffect(( ) => {
        if(!!clientSecret){
            setStripeClientSecret(clientSecret)
            setDonation(user_donation)
            setStep(2)
        }
    }, [clientSecret])

    const handleIncreaseDonation = () => {
        setDonation(parseInt(donation) + 1)
        setPreSelectedDonation(null)
    }

    const handleDecreaseDonation = () => {
        if (donation > 0) {
            setDonation(parseInt(donation) - 1)
            setPreSelectedDonation(null)
        }
    }

    const handleCustomDonationBtns = (amount: any) => {
        setDonation(amount)
        setPreSelectedDonation(amount)
    }

    const isBtnSelected = (amount: any) => {
        return (preselectedDonation == amount || donation == amount) ? true : false
    }

    const handleSecretGeneration = async () => {
        setIsLoading(true)
        const response = await getPaymentSecret(website?.id, { amount: donation, description: `Donation for ${contributionType?.type} ${contributionType?.name}`, calculateFee: true })
        Mixpanel.track(
            `VILLAGER_${contributionType?.name?.toLowerCase().replace(/\s+/g, '_')}_initiate`,
            {
                website_slug: website?.slug,
            }
        )
        PostHog.track(`villager_fund_initiate`, {
            fund_type: contributionType?.name?.toLowerCase().replace(/\s+/g, '_'),
            website_slug: website?.slug
        })
        setIsLoading(false)
        setStripeClientSecret(response?.data?.client_secret)
        setStep(2)
    }

    // const submitGuestInfo = async (payload: any) => {
    //     setIsLoading(true)
    //     await addGuest(website?.id, payload)
    //     setIsLoading(false)

    //     form.resetFields()
    //     setStep(1)
    //     close()
    // }

    const handleModalClose = () => {
        close()
        setPreSelectedDonation(null)
        setDonation(35)
        setStep(1)
        navigate(`/${website?.slug}`)
    }


    const getBtnText = () => {
        switch(contributionType?.name){
            case "Believe Diapers":
                return "Send Diapers"
			case "Wellness":
				return "Send Health"
			case "SquareBaby Baby Food":
				return "Send a Delivery"
			case "Zomee Breastfeeding Supplies":
				return "Send Breastfeeding Support"
            default:
                return "Contribute"
        }
    }

    const getHeadingText = () => {
        switch(contributionType?.name){
            case "Believe Diapers":
                return <>Your sending {getFirstNameAndLastName(website?.village_owner)?.first_name} ${donation}<br />worth of diapers!</>
            case "Wellness":
                return <>Your sending {getFirstNameAndLastName(website?.village_owner)?.first_name} ${donation}<br />worth of wellness!</>
            case "SquareBaby Baby Food":
                return <>Your sending {getFirstNameAndLastName(website?.village_owner)?.first_name} ${donation}<br />worth of organic baby food,<br />delivered to their door</>
            case "Zomee Breastfeeding Supplies":
                return <>Your sending {getFirstNameAndLastName(website?.village_owner)?.first_name} ${donation}<br />worth of feeding support!</>
            default:
                return <>You’re Supporting<br />{getFirstNameAndLastName(website?.village_owner).first_name}</>
        }
    }
    return (
        <>
            <Modal show={show} destroyOnClose close={handleModalClose} head={false} title={``} classes={["viewContributionModal"]}>
                <Row className="contributionModalContainer" gutter={[24, 0]}>
                    {
                        step === 1 ?
                            <>
                                <Col>
                                    <div className="leftSide">
                                        <Paragraph classes={["headline"]}>{getHeadingText()}</Paragraph>
                                        <div className="imageBox">
                                            <Image
                                                src={contributionType?.type === "fund" ?
                                                    website?.photos?.[0]?.img_url || `../assets/placeholders/villie/image${website?.slug?.length % 3}.png`
                                                    :
                                                    `../assets/brands/${contributionType?.image}`
                                                }
                                                alt="logo" preview={false}
                                                className="image"
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col className="rightColumn">
                                    <div className="rightSide">
                                        <Row className="customDonation" justify="space-around" align="middle">
                                            {
                                                !(brandsWithOutBtn?.includes(contributionType?.name)) &&
                                                <Col>
                                                    <Image
                                                        className="btn"
                                                        src={"../assets/newIcons/donation-decrease.svg"}
                                                        alt="-" preview={false}
                                                        onClick={handleDecreaseDonation}
                                                    />
                                                </Col>
                                            }
                                            <Col>
                                                <Paragraph classes={["donation"]}>${donation}</Paragraph>
                                            </Col>
                                            {
                                                !(brandsWithOutBtn?.includes(contributionType?.name)) &&
                                                <Col>
                                                    <Image
                                                        className="btn"
                                                        src={"../assets/newIcons/donation-increase.svg"}
                                                        alt="-" preview={false}
                                                        onClick={handleIncreaseDonation}
                                                    />
                                                </Col>
                                            }
                                        </Row>

                                        <Row className="preSelectDonation">
                                            {
                                                (contributionType?.type === "brand" ? contributionType?.otherAmount : [40, 75, 100, 145])?.map((amount: any, index:number) => <Col key={index} className={classNames("btn", { "isPreSelectedBtnSelected": isBtnSelected(amount) })} onClick={() => handleCustomDonationBtns(amount)}>${amount}.00</Col>)
                                            }
                                            
                                            {/* <Col className={classNames("btn", { "isPreSelectedBtnSelected": isBtnSelected(75) })} onClick={() => handleCustomDonationBtns(75)}>$75.00</Col>
                                            <Col className={classNames("btn", { "isPreSelectedBtnSelected": isBtnSelected(100) })} onClick={() => handleCustomDonationBtns(100)}>$100.00</Col>
                                            <Col className={classNames("btn", { "isPreSelectedBtnSelected": isBtnSelected(145) })} onClick={() => handleCustomDonationBtns(145)}>$145.00</Col> */}
                                        </Row>

                                        <Button
                                            type="theme"
                                            classes={["contributionBtn"]}
                                            text={isLoading ? <Spinner /> : getBtnText()}
                                            action={handleSecretGeneration}
                                        />
                                    </div>

                                </Col>
                            </>
                            :
                                // step === 2 ?
                                    <Col>
                                        <Payment
                                            donation={donation}
                                            nextStep={() => {
                                                // message: any
                                                // if(!!message) setMessgae(message)
                                                handleModalClose()
                                                setIsFollowOurVillieModalVisible(true)
                                                navigate(`/${website?.slug}`)
                                            }}
                                            stripeClientSecret={stripeClientSecret}
                                            data={{ website: website, donationDetail: contributionType }}
                                        />
                                    </Col>
                                // :
                                
                                //     <>
                                //         <Col span={24}>
                                //             <br />
                                //             <Alert message={message} type="info" />
                                //         </Col>
                                //         <Col span={24} className="headlineStep3" style={{marginTop: "30px", marginBottom: "30px"}}>Wanna receive updates from {getFirstNameAndLastName(website?.village_owner)?.first_name}'s village</Col>
                                //         <Col span={24}>
                                //             <Form
                                //                 form={form}
                                //                 name="basic"
                                //                 onFinish={submitGuestInfo}
                                //                 layout="vertical"
                                //                 autoComplete="off"
                                //             >
                                //                 <Input
                                //                     name="email"
                                //                     placeholder="Email"
                                //                     label="What’s your email?"
                                //                     required
                                //                     classes={["c-bg-w"]}
                                //                 />
                                //                 <Input
                                //                     name="name"
                                //                     placeholder="Name"
                                //                     label="What’s your name?"
                                //                     required
                                //                     classes={["c-bg-w"]}
                                //                 />
                                //                 <Input
                                //                     label="What’s your phone number?"
                                //                     name="phone"
                                //                     placeholder='(XXX) XXX-XXXX'
                                //                     required
                                //                     minLength={10}
                                //                 />
                                //                 <Input
                                //                     name="relation_with_mom"
                                //                     placeholder="Relation with mom"
                                //                     label="What’s your relation with mom?"
                                //                     required
                                //                     classes={["c-bg-w"]}
                                //                 />
                                //                 <Button
                                //                     htmlType="submit"
                                //                     type="base3"
                                //                     text={isLoading ? <Spinner /> : "Yes"}
                                //                     classes={["w-100-p"]}
                                //                 />
                                //             </Form>
                                //         </Col>
            
                                //     </>
                                
                           
                    }
                </Row>
            </Modal>
            
            <FollowOurVillieModal 
                website={website}
                show={isFollowOurVillieModalVisible}
                showSuccess={true}
                close={() => setIsFollowOurVillieModalVisible(false)}
            />
        </>
    )
}

export default ContributionModal;