import React from "react";
import "./styles.scss";
import Modal from "shared/components/modal";
import BButton from "shared/components/bButton";
import { useNavigate } from "react-router-dom";
import { getPackagePrice } from "shared/utils/helper";
import { PostHog } from "shared/services/posthog";

interface props {
	show: boolean;
	close: any;
	data: any;
}

const UpgradeTierModal: React.FC<props> = ({ show, close, data }) => {
    const navigate = useNavigate();

	return (
		<>
			<Modal show={show} close={close} head={false} classes={["upgradeTierModal"]}>
				<div className="upgradeTierModalContainer">
                    <p className="title">Update Your Plan</p>
                    <div className="plansBox">
                        <div className="tier">
                            <div className="tierBadge">BASIC PLAN</div>
                            <p className="tierPrice">Free</p>
                            <p className="tierCopy">Villie will always be free.</p>
                            <br />
                            {
                                ["Up to 30 Villagers", "Accepts Funds and Donations", "Gender Reveal Game", "8 Color Themes", "Customer Support"].map((item, index) => (
                                    <div className="tierDetails" key={index}>
                                        <img className="icon" src="../assets/newIcons/purple-filled-heart.svg" alt="heart" />
                                        <p className="copy">{item}</p>
                                    </div>
                                ))
                            }
                            
                        </div>

                        <div className="tier" style={{background: "#9745DA"}}>
                            <div className="tierBadge">UPGRADED PLAN</div>
                            <p className="tierPrice" style={{color: "#FFF"}}>${getPackagePrice("tier1")}</p>
                            <br />
                            {
                                [   "Up to 500 Villagers", 
                                    "Accepts Funds and Donations", 
                                    "Access to All Games", 
                                    "Premium Themes", 
                                    "Customer Support",
                                    "Ad-Free Experience",
                                    "Password Protection",
                                    "Extra Page Customization",
                                    "Exclusive Discounts",
                                    "Villie Freebie Box"
                                ].map((item, index) => (
                                    <div className="tierDetails" key={index}>
                                        <img className="icon" src="../assets/newIcons/purple-filled-heart.svg" alt="heart" />
                                        <p className="copy" style={{color: "#FFF", fontSize: ".85rem"}}>{item}</p>
                                    </div>
                                ))
                            }
                            <br />
                            <BButton 
                                text="Upgrade your plan now" 
                                type="base4" 
                                action={() => {
                                    close();
                                    PostHog.track("upgrade_start", {
                                        website_id: data?.id,
                                        website_slug: data?.slug
                                    })
                                    navigate(`/upgrade?package=tier1&website_slug=${data?.slug}&website_id=${data?.id}`)
                                }}
                            />
                        </div>

                    </div>
                    
                    {/* <div className="tier">
                        <p>Tier2</p>
                        <p>${getPackagePrice("tier2")}</p>
                        <BButton 
                            text="Upgrade" 
                            type="base4" 
                            action={() => navigate(`/upgrade?package=tier2&website_slug=${data?.slug}&website_id=${data?.id}`)}
                        />
                    </div> */}
                </div>
			</Modal>
		</>
	);
};

export default UpgradeTierModal;
