import React from 'react';
import { Button as AntdButton, Tooltip } from 'antd';
import classNames from 'classnames';
import './styles.scss';

interface props {
    text: any;
    type?:
        | 'base1'
        | 'base2'
        | 'base3'
        | 'base4'
        | 'base5'
        | 'base6'
        | 'base7'
        | 'base8'
        | 'transparent'
        | 'primary'
        | 'secondary'
        | 'theme'
        | 'disabled';
    action?: any;
    styles?: any;
    icon?: any;
    tooltip?: string;
    htmlType?: 'button' | 'submit' | 'reset' | undefined;
    classes?: string[];
    disabled?: boolean;
}

const BButton: React.FC<props> = ({
    text,
    action,
    styles,
    classes = [],
    icon,
    tooltip,
    type = 'primary',
    htmlType = 'button',
    disabled = false,
}) => {
    const getButtonClass = (
        type:
            | 'base1'
            | 'base2'
            | 'base3'
            | 'base4'
            | 'base5'
            | 'base6'
            | 'base7'
            | 'base8'
            | 'transparent'
            | 'primary'
            | 'secondary'
            | 'theme'
            | 'disabled'
    ) => {
        const classNames: any = {
            base1: 'base1Btn',
            base2: 'base2Btn',
            base3: 'base3Btn',
            base4: 'base4Btn',
            base5: 'base5Btn',
            base6: 'base6Btn',
            base7: 'base7Btn',
            base8: 'base8Btn',
            transparent: 'transparent',
            primary: 'primaryBtn',
            secondary: 'secondaryBtn',
            theme: 'themeBtn',
            disabled: 'disabledBtn',
        };

        return classNames[type];
    };

    return (
        <>
            <Tooltip title={tooltip}>
                <AntdButton
                    className={classNames('buttonContainer', getButtonClass(type), ...classes)}
                    onClick={action}
                    style={{ ...styles }}
                    icon={icon}
                    htmlType={htmlType}
                    disabled={disabled}
                >
                    {text}
                </AntdButton>
            </Tooltip>
        </>
    );
};

export default BButton;
