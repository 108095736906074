import "./styles.scss";
import React, { useState } from 'react';
import Modal from 'shared/components/modal';
import Button from "shared/components/bButton";
import { Row, Col, Form } from 'antd';
import Input from "shared/components/binput";
import TextArea from "shared/components/btextArea";
import Spinner from "shared/components/spinner";
import { addNote } from "modules/website/redux/operators";
import Notification from "shared/components/notification";
import { Mixpanel } from "shared/services/mixpanel";
import { PostHog } from "shared/services/posthog";

interface props {
    show: boolean
    close: any
    website: any
}


const AddNotesModal: React.FC<props> = ({ website, show, close }) => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false)

    const handleAddNotes = async (payload: any) => {
        setIsLoading(true)
        const response = await addNote(website?.id, payload)
        setIsLoading(false)

        if(response?.status === "success"){
            Mixpanel.track("VILLAGER_adds_a_note", {
                website_id: website?.id,
                website_slug: website?.slug
            })
            PostHog.track("villager_add_note", {})
            form.resetFields()
            Notification("success", "Note added successfully")
            close()
            window.location.reload();
        }
        else{
            Notification("error", response?.message)
        }
    }

    const handleModalClose = () => {
        close()
    }

    return (
        <>
            <Modal show={show} close={handleModalClose} head={false} title="Share some love">
                <Row className='addNotesModalContainer'>
                    <Col span={24}>
                        <br />
                        <Form
                            name="addNotesForm"
                            onFinish={handleAddNotes}
                            autoComplete="off"
                            form={form}
                            layout="vertical"
                        >
                            <Row gutter={[0, 0]} justify="space-between">
                                <Col span={24}>
                                    <Input
                                        label="What's your name?"
                                        name="user_name"
                                        placeholder='Your name'
                                        required
                                    />
                                </Col>
                               
                                <Col span={24}>
                                    <TextArea
                                        label="Add your note."
                                        name="note"
                                        placeholder={"Your note"}
                                        rows={5}
                                        required
                                    />
                                </Col>

                                <Col span={24}>
                                    <a className="blog-link" href="https://www.villie.com/article/what-to-write-in-a-baby-shower-card" target='_blank' rel="noopener noreferrer">
                                        How to Write Heartfelt Messages for New and Expecting Parents
                                    </a>
                                </Col>
                                <Col span={24}>
                                    <Button
                                        type="base3"
                                        classes={["w-100-p"]}
                                        text={isLoading ? <Spinner /> : "Done"}
                                        htmlType={"submit"}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default AddNotesModal;