import "./styles.scss";
import React, { useEffect, useState } from "react";
import { Form } from "antd";
import Button from "shared/components/bButton";
import Spinner from "shared/components/spinner";
import {
	getMomentObj,
	formatedDate,
} from "shared/utils/helper";
import { createGame, updateGame } from "modules/website/redux/operators";
import Notification from "shared/components/notification";
import Input from "shared/components/binput";
import DatePicker from "shared/components/newDatepicker";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "modules/user/redux/userSlice";
import { Mixpanel } from "shared/services/mixpanel";
import DecisionButtons from "shared/composedComponents/decisionButtons";
import { getWebsite, setWebsite } from "shared/infra/redux/global/global";
import ChartSection from "modules/website/pages/newPreviewMode/games/components/chartSection";
import EndGrgModal from "../endGrgForm";
import { useNavigate } from "react-router-dom";
import { PostHog } from "shared/services/posthog";

interface props {
	setClosingCollapse: any;
}

const AddGRGForm: React.FC<props> = ({ setClosingCollapse }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const data: any = useSelector(getWebsite);
	const [form] = Form.useForm();
	const user = useSelector(getUser);
	const [isLoading, setIsLoading] = useState(false);
	const [awardPrize, setAwardPrize] = useState<null | string>("No");
	const [raiseFunds, setRaiseFunds] = useState<"Yes" | "No" | null>("Yes");
	const [isEndGrgModalVisible, setIsEndGrgModalVisible] = useState(false);

	const handleCreateGame = async (values: any) => {
		if (isLoading) return 0;

		const payload = {
			name: values?.game_name || "Gender Reveal Game",
			prize_name: values?.prize_name !== undefined ? values?.prize_name : null,
			finish_date: formatedDate(values?.finish_date),
			raise_funds: raiseFunds === "Yes",
			// prize_name: null,
			// raise_funds: true,
		};

		setIsLoading(true);
		const response = await createGame(payload);
		setIsLoading(false);

		if (response?.status === "success") {
			dispatch(
				setWebsite({ website: { ...data, game: response?.data?.game } })
			);
			// setClosingCollapse();
			Notification("success", `Gender reveal game created successfully`);
			Mixpanel.track("PARENT_starts_a_grg", {
				$name: user?.name,
				$email: user?.email,
			});
			PostHog.track("add_grg", {})
		} else {
			Notification("error", response?.message);
			setIsLoading(false);
		}
	};

	const handleUpdateGame = async (values: any) => {
		if (isLoading) return 0;

		const payload = {
			name: values?.game_name || "Gender Reveal Game",
			prize_name: values?.prize_name !== undefined ? values?.prize_name : null,
			finish_date: formatedDate(values?.finish_date),
			raise_funds: raiseFunds === "Yes",
		};

		setIsLoading(true);
		const response = await updateGame(data?.game?.id, payload);
		setIsLoading(false);

		if (response?.status === "success") {
			dispatch(
				setWebsite({
					website: { ...data, game: { ...data?.game, ...payload } },
				})
			);
			setClosingCollapse();
			Notification("success", `Gender reveal game updated successfully`);
		} else {
			Notification("error", response?.message);
		}
	};

	useEffect(() => {
		if (!!data?.game) {
			form.setFieldsValue({
				game_name: data?.game?.name,
				prize_name: data?.game?.prize_name,
				finish_date: getMomentObj(data?.game?.finish_date),
			});
			setAwardPrize(!!data?.game?.prize_name ? "Yes" : "No");
			setRaiseFunds(!!data?.game?.raise_funds ? "Yes" : "No");
		}
	}, [data]);


	return (
		<>
			<div className="addGrgFormContainer">
				{
					!data?.game?.winner[0]?.id ?
					<Form
					name="createGameForm"
					onFinish={
						!!data?.game ? handleUpdateGame : handleCreateGame
					}
					autoComplete="off"
					layout="vertical"
					form={form}
				>
					<Input
						label="Name your game"
						placeholder="ex: Johnson Grand Reveal"
						name="game_name"
					/>
					<DatePicker
						label="Game end date"
						placeholder="Select a date"
						required
						name="finish_date"
						classes={["datepickerStep2"]}
						disabledDate={(current: any) => {
							return current && current < moment().endOf("day");
						}}
						errorMessage="Don’t forget to enter your game end date!"
					/>
					<DecisionButtons
						label="Do you want to award a prize to a randomly selected winner that guesses right? Ex: A gift card, Bragging rights"
						actionOnYes={() => setAwardPrize("Yes")}
						actionOnNo={() => setAwardPrize("No")}
						decision={awardPrize === "Yes" ? "Yes" : "No"}
					/>

					{awardPrize === "Yes" && (
						<Input
							placeholder="Game prize"
							name="prize_name"
							errorMessage="Oh no! We can’t play until you make a choice. Please select your game prize!"
							required
						/>
					)}
					<DecisionButtons
						label="Do you want to raise money with your gender reveal game?"
						actionOnYes={() => setRaiseFunds("Yes")}
						actionOnNo={() => setRaiseFunds("No")}
						decision={raiseFunds}
					/>

					{!!data?.game && (
						<div className="m-t-3-p">
							<ChartSection data={data} />
						</div>
					)}

					<Button
						type={"base4"}
						htmlType="submit"
						classes={["w-100-p", "m-t-3-p"]}
						text={
							isLoading ? (
								<Spinner />
							) : (
								`${!!data?.game ? "Update" : "Create"} Game`
							)
						}
					/>

					{
						!!data?.game && 
						<>
						<Button
							type="base5"
							classes={["w-100-p", "m-t-3-p"]}
							text={"End game and announce"}
							action={() => setIsEndGrgModalVisible(true)}
						/>
						<Button
							type="transparent"
							classes={["w-100-p", "m-t-3-p"]}
							text={"Preview"}
							action={() => navigate(`/${data?.slug}?preview=true#games`)}
						/>
						</>
					}
					</Form>
					:
					<div className="winnerSection">
						<p className="gameName">
							{data?.game?.name}
						</p>
						<div className="winnerCard" style={{border: `1px solid ${data?.game?.baby_gender === 1 ? "#CC8DFF" :  "#5263FF"}`}}>
                            <p className="title" style={{color: data?.game?.baby_gender === 1 ? "#CC8DFF" :  "#5263FF"}}>winner {data?.game?.winner[0]?.voter_first_name} {(data?.game?.winner[0]?.voter_last_name)?.substring(0,1)}.</p>
                            {/* <Paragraph className="subtitle">{data?.game?.winner[0]?.voter_first_name} {(data?.game?.winner[0]?.voter_last_name)?.substring(0,1)}.</Paragraph> */}
							<p className="subtitle">It’s a {data?.game?.baby_gender === 0 ? "boy" : "girl"}</p>
                        </div>
						{!!data?.game && (
							<div className="m-t-3-p w-100-p">
								<ChartSection data={data} />
							</div>
						)}
					</div>
				}
				
			</div>

			<EndGrgModal
				show={isEndGrgModalVisible}
				close={() => setIsEndGrgModalVisible(false)}
				data={data}
				afterAction={() => console.log("afterAction")}
			/>
		</>
	);
};

export default AddGRGForm;
