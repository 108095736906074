import Button from 'shared/components/bButton';
import './styles.scss';
import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'antd';
import { createGuessWhoGame } from 'modules/website/redux/operators';
import { useDispatch, useSelector } from 'react-redux';
import { getWebsite, setGuessWhoGame, setUpgradeModalVisible,} from 'shared/infra/redux/global/global';
import Notification from 'shared/components/notification';
import { getUser } from 'modules/user/redux/userSlice';
import Spinner from 'shared/components/spinner';
import { Mixpanel } from 'shared/services/mixpanel';
import QuestionBox from './components/questionBox';
import { getFirstNameAndLastName, isFreeTrialValid } from 'shared/utils/helper';
import { useNavigate } from 'react-router-dom';
import { PostHog } from 'shared/services/posthog';

const defaultQuestions = [
    'Who will be the stricter parent?',
    'Who’s fashion sense will the baby have?',
    'Who will the baby look more like?',
    'Who will sing better lullabies?',
    'Who will take more photos?',
];

const defaultParentNames = ['Parent One', 'Parent Two'];

interface props {
    setClosingCollapse: any;
}

const GuessWhoGameForm: React.FC<props> = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const formRef = useRef<HTMLDivElement>(null);

    const data: any = useSelector(getWebsite);
    const user = useSelector(getUser);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedQuestion, setSelectedQuestion] = useState<any>({
        id: null,
        question: null,
    });
    const [selectedParent, setSelectedParent] = useState<any>({
        id: null,
        name: null,
    });

    const [questions, setQuestions] = useState(defaultQuestions);
    const [parentNames, setParentNames] = useState(defaultParentNames);

    const [gameStep, setGameStep] = useState(1);

    const saveParentName = (index: number, value: string) => {
        const updatedParents = [...parentNames];
        updatedParents[index] = value;
        setParentNames(updatedParents);
        setSelectedParent({ id: null, name: null });
    };

    const saveQuestion = (index: number, value: string) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index] = value;
        setQuestions(updatedQuestions);
        setSelectedQuestion({ id: null, question: null });
    };

    const handleCreateGame = async () => {
        const payload = {
            websiteId: data?.id,
            questions,
            parentNames,
        }

        if(data?.package !== "tier1" && !isFreeTrialValid(data?.free_trial_started_at)){
            await dispatch(setGuessWhoGame({guessWhoGame: payload}))
            return dispatch(setUpgradeModalVisible({ isUpdgradeModalVisible: true }))
        }

        setIsLoading(true);
        const response = await createGuessWhoGame(payload);
        setIsLoading(false);
        
        if (response?.status === 'success') {
            Mixpanel.track('PARENT_starts_a_guess_who', {
                $name: user?.name,
                $email: user?.email,
            });
            PostHog.track('add_guesswho', {})
            navigate(`/${data?.slug}#guess-who`);

        } else {
            Notification('error', response?.message);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setParentNames([getFirstNameAndLastName(data?.village_owner)?.first_name, 'Parent Two']);
    }, [data])

    const getProgressPercentage = () => {
        if(data?.guessWho?.results?.totalVotesParentOne === 0 && data?.guessWho?.results?.totalVotes === 0) return "50%"
        const percentage = (data?.guessWho?.results?.totalVotesParentOne / data?.guessWho?.results?.totalVotes) * 100 

        if(percentage === 0) return "5%"
        else if(percentage === 100) return "98%"
        else return percentage + "%"
    }

    return (
        <>
            <div className="guessWhoGameFormContainer">
                <div className="guessBadge">GUESS WHO GAME</div>

                {data?.guessWho?.game ? (
                    <>
                    <div className="chartSectionArea">
                                    <div className='bar'>
                                        <div className='internalBar' style={{width: `${getProgressPercentage()}`}}></div>
                                    </div>
                                    <div className='parentName'>
                                        <p>{data?.guessWho?.game?.parent_one}</p>
                                        <p>{data?.guessWho?.game?.parent_two}</p>
                                    </div>
                                </div>
                                <div className='showAllResults'>
                                <p className='heading'>All Answers:</p>
                                <div className='box'>
                                    {Object.entries(data?.guessWho?.results?.questionDetails)?.map(([question, details]: any, index) => (
                                        <div className='individual' key={index}>
                                            <p className='q'>{question}</p>
                                            <p className='winner'>{details.winner === "Parent One"? data?.guessWho?.game?.parent_one : details.winner === "Parent Two"? data?.guessWho?.game?.parent_two : details?.winner}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                    
                    </>
                ) : (
                    <>
                        <div className="guessWhoTitle">
                            {gameStep === 1 ? 'Add Parent Names' : 'Get ready for fun as friends and family vote on who the baby will take after'}
                        </div>

                        {gameStep === 1 ? (
                            <QuestionBox
                                data={parentNames}
                                selectedItem={selectedParent}
                                setSelectedItem={setSelectedParent}
                                form={form}
                                formRef={formRef}
                                saveItem={saveParentName}
                                inputNames={defaultParentNames}
                            />
                        ) : (
                            <QuestionBox
                                data={questions}
                                selectedItem={selectedQuestion}
                                setSelectedItem={setSelectedQuestion}
                                form={form}
                                formRef={formRef}
                                saveItem={saveQuestion}
                                inputNames={questions.map((_, index) => `question-${index}`)}
                            />
                        )}
                    </>
                )}

                {
                    !data?.guessWho?.game && (
                        <Button
                            type={'base4'}
                            htmlType="submit"
                            classes={['w-100-p', 'm-t-3-p']}
                            text={isLoading ? <Spinner /> : gameStep === 1 ? `Next Step` : `Create Game`}
                            action={gameStep === 1? () => setGameStep(2) : handleCreateGame}
                        />
                    )
                }
               
            </div>
        </>
    );
};

export default GuessWhoGameForm;
