import "./styles.scss";
import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import Input from "shared/components/binput"
import Button from "shared/components/bButton";
import UploadImagesForOneImage from "shared/components/uploadImagesForOneImage";
import Spinner from "shared/components/spinner";
// import { blobToBase64 } from "shared/utils/helper";
import DatePicker from "shared/components/newDatepicker";
import Notification from "shared/components/notification";
import { getMomentObj, newConvertBlobToBase64 } from "shared/utils/helper";
import { addNewEvent, deleteEvent, editEvent } from "modules/website/redux/operators";
import { Mixpanel } from "shared/services/mixpanel";
// import { blobToBase64 } from "shared/utils/helper";
// import { Mixpanel } from "shared/services/mixpanel";
import { useSelector } from "react-redux";
import { getUser } from "modules/user/redux/userSlice";
import useWindowSize from "shared/hooks/use-window-size";
// import { addNewEvent } from "modules/website/redux/operators";
import TextArea from "shared/components/btextArea";
import { PostHog } from "shared/services/posthog";

interface props {
    handleFormClosing?: any
    website?: any

    isUpdating?: boolean
    setData?: any
    data?: any
    events?: any
}

const AddEventForm: React.FC<props> = ({ handleFormClosing, website, isUpdating, data, setData, events }) => {
    const {width} = useWindowSize()
    const user = useSelector(getUser)
    const [form] = Form.useForm();
    const [images, setImages] = useState<any>([])
    const [isLoading, setIsLoading] = useState(false)

    const [deletedImages, setDeletedImages] = useState([])
    const [isDeleting, setIsDeleting] = useState(false)
    // const convertBlobToBase64 = async (images: any) => {
    //     if (!images) return []

    //     return await Promise.all(images?.map(async (image: any) => {
    //         const img: any = await blobToBase64(image?.blob)
    //         return { image: img.replace("application/zip", "image/jpeg"), type: "jpeg" }
    //     }))
    // }

    const handleAddEvent = async (values: any) => {
        if (isLoading) return

        const payload = {
            "milestone_photos": await newConvertBlobToBase64(images),
            ...values,
        }

        setIsLoading(true)
        const response = await addNewEvent(website?.id, payload)
        
        setIsLoading(false)

        if (response?.status === "success") {
            Mixpanel.track(
                "PARENT_adds_an_event",
                {
                    $name: user?.name,
                    $email: user?.email,
                    website_id: website?.id,
                    website_slug: website?.slug
                }
            )
            PostHog.track("add_event", {})
            setImages([])
            form.resetFields()
            handleFormClosing(true)
            Notification("success", "Event added successfully")
        }
        else {
            Notification("error", response?.message)
        }
    }

    const handleEditEvent = async (values: any) => {
        if(isLoading) return

        const payload = {
            "milestone_photos": [
                ...deletedImages,
                ...await newConvertBlobToBase64(images?.filter((i: any) => i?.blob))
            ],
            ...values,
        }

        setIsLoading(true)
        const response = await editEvent(data?.website_id, data?.id, payload)
        setIsLoading(false)

        if (response?.status === "success") {
            Mixpanel.track(
                "PARENT_edit_an_event",
                {
                    $name: user?.name,
                    $email: user?.email,
                    website_id: website?.id,
                    website_slug: website?.slug
                }
            )

            setData(events.map((e: any) =>
                e.id === response?.data?.milestone?.id
                    ? { ...e, ...response.data.milestone }
                    : e
            ));
            setImages([])
            form.resetFields()
            handleFormClosing()
            Notification("success", "Event edit successfully")
        }
        else {
            Notification("error", response?.message)
        }

    }

    const handleDelete = async () => {
        setIsDeleting(true)
        const response = await deleteEvent(data?.website_id, data?.id)

        if(response?.status === "success"){
            Mixpanel.track(
                "PARENT_delete_an_event",
                {
                    $name: user?.name,
                    $email: user?.email,
                    website_id: website?.id,
                    website_slug: website?.slug
                }
            )
            setData(events.filter((e: any) => e?.id !== data?.id));
            handleFormClosing()
            Notification("success", "Event deleted successfully")
        }
        else{
            Notification("error", response?.message)
        }
        setIsDeleting(false)
    }

    useEffect(() => {
        if (isUpdating) {
            form.setFieldsValue({
                title: data?.title,
                location: data?.location,
                date: getMomentObj(data?.date),
                notes: data?.notes
            });

            if(data?.images?.length > 0){
                setImages(data?.images)
            }
            else{
                setImages([])
            }
        }
    }, [isUpdating, data]);

    return (
        <>
            <Form
                name="addMilestoneForm"
                onFinish={isUpdating ? handleEditEvent : handleAddEvent}
                autoComplete="off"
                form={form}
                layout={"vertical"}
            >
                <div className='addEventFormContainer'>
                    <Input
                        label={"Name your event"}
                        name="title"
                        placeholder={"Ex: Jayden’s Gender Reveal Party"}
                    />
                    <Input
                        name="location"
                        label={"Where is the event?"}
                        placeholder='Location: address or zoom link'
                        required
                    />
                    <DatePicker
                        label={"When is the event?"}
                        name="date"
                        placeholder='Select a date'
                        format="MM-DD-YYYY"
                        required
                    />
                    <TextArea
                        name="notes"
                        label={"Add details"}
                        placeholder='Ex: dress code, what to bring, etc.'
                    />
                    {/* <UploadImages
                        full={true}
                        name="Photos"
                        images={images}
                        onChange={(images: any) => setImages(images)}
                        noOfImagesAllowed={1}
                        label="Add a cover image"
                        btnSublabel=""
                    /> */}
                    <UploadImagesForOneImage
                        full={true}
                        name="Photos"
                        images={images}
                        onChange={(images: any) => setImages(images)}
                        onDelete={(image: any) => setDeletedImages(image)}
                        deletedImages={deletedImages}
                        noOfImagesAllowed={1}
                        btnSublabel={""}
                    />


{
                        isUpdating? 
                        <div className={width > 450 ? "d-h-sb" : "d-v-c"}>
                            <Button
                                classes={[width > 450 ? "w-48-p" : "w-100-p", "m-t-3-p"]}
                                type="base4"
                                htmlType="submit"
                                text={isLoading ? <Spinner /> : `Edit event`}
                            />
                            <Button
                                classes={[width > 450 ? "w-48-p" : "w-100-p", "m-t-3-p"]}
                                type="base5"
                                action={handleDelete}
                                text={isDeleting ? <Spinner /> : `Delete event`}
                            />
                        </div>
                        :
                        // <Button
                        //     classes={["w-100-p", "m-t-3-p"]}
                        //     type="base4"
                        //     htmlType="submit"
                        //     text={isLoading ? <Spinner /> : `Add update`}
                        // />
                        <Button
                            type={"base4"}
                            htmlType="submit"
                            classes={["w-100-p", "m-t-3-p"]}
                            text={isLoading ? <Spinner /> : "Add Event"}
                        />
                    }
                </div>
            </Form>
        </>
    )
}


export default AddEventForm;