import "./styles.scss"
import React, { useEffect, useState } from 'react'
import PieChart from "shared/components/pieChart"
import useWindowSize from "shared/hooks/use-window-size"
import { calculateVoterPercentage } from "shared/utils/helper";
import { Typography } from "antd";
import { getGender } from "shared/utils/helper";

const {Paragraph} = Typography

interface props {
    data: any
}

const ChartSection: React.FC<props> = ({data}) => {
    const {width} = useWindowSize()
    const [votesCount, setVotesCount] = useState<any>(null)
	const createdAt = new Date(data?.game?.created_at)
	const launchAt = new Date('2022-11-01')
	const [votes, setVotes] = useState<any>([])

    useEffect(() => {
		if(createdAt < launchAt){
			if(data?.game?.vote_counts){
				setVotes([...data?.game?.vote_counts])
			}
			if(data?.game?.summary){
				setVotesCount({boy: data?.game?.summary?.boy_votes, girl: data?.game?.summary?.girl_votes})
			}
		}
		else{
			if(data?.game?.vote_counts){
				setVotes([{no_of_votes: 1, voter_first_name: "Kim", voter_last_name: "at Villie", boy_or_girl: getGender(data?.game?.contest?.name)}, ...data?.game?.vote_counts])
			}
			if(data?.game?.summary){
				const temp = getGender(data?.game?.contest?.name) == 0 ? {boy: data?.game?.summary?.boy_votes + 1, girl: data?.game?.summary?.girl_votes} : {boy: data?.game?.summary?.boy_votes, girl: data?.game?.summary?.girl_votes + 1}
				setVotesCount(temp)
			}
		}
		
	}, [data?.game])

    return (
        <div className="chartSectionContainer">
            {
                !(width > 450) &&
                <div className="votesCount">
                    <Paragraph className="title">Total Votes</Paragraph>
                    <Paragraph className="subtitle">{votes?.length}</Paragraph>
                </div>
            }
            {
                !(votesCount?.boy === 0 && votesCount?.girl === 0) &&
                <div className="chart">
                    <PieChart
                        data={[
                            { name: "Girl", value: calculateVoterPercentage(votesCount?.boy, votesCount?.girl, "girl"), color: "#D9ABFE" },
                            { name: "Boy", value: calculateVoterPercentage(votesCount?.boy, votesCount?.girl, "boy"), color: "#5263FF" },
                        ]}
                        forMobile={!(width > 450)}
                    />
                </div>
            }
            {
                width > 450 &&
                <div className="votesCount">
                    <Paragraph className="title">Total Votes</Paragraph>
                    <Paragraph className="subtitle">{votes?.length}</Paragraph>
                </div>
            }
            {
                // data?.game?.raise_funds === 1 &&
                false &&
                <div className="moneyRaised">
                    <Paragraph className="title">Funds Raised</Paragraph>
                    <Paragraph className="subtitle">${data?.game?.summary?.money_raised}</Paragraph>
                </div>
            }
        </div>
    )
}

export default ChartSection